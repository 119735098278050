import { Turbo } from '@hotwired/turbo-rails';

Turbo.session.drive = false;
import ResizeObserver from 'resize-observer-polyfill';

// Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'raf/polyfill'; // React 16 requires requestAnimationFrame polyfill

// jQuery stuff
import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ujs/src/rails.js';
import 'jquery.hotkeys/jquery.hotkeys';
import 'jquery-mousewheel/jquery.mousewheel';
import 'jquery-serializejson';
import 'blueimp-file-upload/js/jquery.fileupload.js';
import 'signature-pad/assets/flashcanvas.js';
import 'signature-pad/jquery.signaturepad.min.js';
import 'jquery-ui/ui/widgets/mouse';
import './vendor/jquery-ui-touch-punch/jquery.ui.touch-punch.js';
import './vendor/jquery.autocomplete';
import './shared/load_bootstrap';

import './shared/uppy_web_component';
import './shared/stimulus';

import each from 'lodash/each';
import moment from 'moment';
import 'twix';
import hopscotch from 'hopscotch';
import autosize from 'autosize';

import { initializeSentry } from './initializeSentry';
import menuControl from './plugins/menu_control';
import utils, { detectIE, initDatePickers, initDeleteConfirmModal, renderReactComponent } from 'shared/utils';
import selectableTable from './selectable_table';
import { maybeRunTour, runTourWhenLoaded } from './shared/hopscotch_helpers';
import { CONCENTRATION_CHOICES, concentrationChoiceName } from 'modules/daily_tracking/constants';
import TextileTextarea from './shared/components/textile_textarea/textile_textarea';
import retry from './shared/retry';
import openRemoteSheet from './shared/openRemoteSheet';
import chooseProfilePhoto from './choose_profile_photo';
import * as FormHelper from 'shared/form_helper';
import * as ConferenceReportHelper from './conference_report_helper';
import * as browserInformation from './shared/browser_information';
import { modUrl } from './shared/mod_url';

import './chart_helper';
import './preferences';
import './modules/daily_tracking/calendar';
// stylesheets
import 'modules/notifications/components/notifications.scss'; // there is no component to attach this to
import { baseURL, resetApi } from 'shared/api';
import { joinUrlParts } from 'shared/routes';
import { schoolUrlHelper } from 'shared/school_url_helper';
import ConfirmDepartureHandler from './modules/confirm_departure_handler.js';
import { find, toPairs, uniq } from 'shared/ramda_loader';
import { PollingResults } from 'shared/components/polling_results';
import { openConfirmModal, openRemoteModal, replaceModal, replaceWithErrorPage } from 'shared/modal_helpers';
// we have to load bootstrap separately from the index because it's less
import './general_stylesheets/index.scss';

import './csv_templates';

import './images';

import { ajaxErrorHandler } from 'shared/error_handling';
import objectStore from 'shared/object_store';
import { csrfTokenHeaders } from 'shared/csrf_token';

initializeSentry();
window.$ = $;
window.moment = moment;
window.autosize = autosize;
window.ResizeObserver = ResizeObserver;

// lodash/ramda utilities
window.each = each;
window.find = find;
window.uniq = uniq;
window.toPairs = toPairs;

// polyfills

window.renderReactComponent = renderReactComponent;
window.detectIE = detectIE;

// Hopscotch related functionality
window.hopscotch = hopscotch;
window.maybeRunTour = maybeRunTour;
window.runTourWhenLoaded = runTourWhenLoaded;

window.modUrl = modUrl;

window.selectableTable = selectableTable;

function setupPage(event) {
  utils.init();
  recordTurboLoadBreadcrumbs(event);

  // Set up the API. If we're on a page without school url, this will blow up,
  // so default to standard call.
  try {
    resetApi(joinUrlParts(baseURL, schoolUrlHelper('')), csrfTokenHeaders());
  } catch (e) {
    resetApi(baseURL, csrfTokenHeaders());
  }
}

function recordTurboLoadBreadcrumbs(event) {
  if (!window.Sentry) {
    return;
  }

  window.Sentry.addBreadcrumb({
    category: 'navigation',
    data: {
      type: event.type,
      to: event.detail.url,
    },
    level: 'info',
  });
}

document.addEventListener('turbo:load', setupPage);

//  Single Page Apps

export default {
  ClassroomPlacement: () => import('./entrypoints/classroom_placement'),
  ClassroomProgress: () => import('./entrypoints/classroom_progress'),
  ChildProgress: () => import('./entrypoints/child_progress'),
  Standards: () => import('./entrypoints/standards_table'),
  GettingStarted: () => import('./entrypoints/getting_started'),
  Announcements: () => import('./entrypoints/announcements'),
  HomepageSidebar: () => import('./entrypoints/homepage_sidebar'),
  Posts: () => import('./entrypoints/activity'),
  MeetingNotes: () => import('./entrypoints/meeting_notes'),
  SuperadminDashboard: () => import('./entrypoints/sa_dashboard'),
  LessonEditor: () => import('./lessoneditor/init'),
  LessonEditor2: () => import('./entrypoints/lesson_editor'),
  LessonSetMerge: () => import('./entrypoints/lesson_set_merge'),
  TemplateEditor: () => import('./entrypoints/template_editor'),
  ScreeningQuestionsEditor: () => import('./entrypoints/screening_questions_editor'),
  DailyTracking: {
    init: () => import('./entrypoints/daily_tracking'),
    concentrationChoiceName,
    CONCENTRATION_CHOICES,
  },
  Attendance: () => import('./entrypoints/attendance'),
  DistanceDropoff: () => import('./entrypoints/distance_dropoff'),
  LessonPlan: () => import('./entrypoints/lesson_plan'),
  LastDay: () => import('./modules/children/components/last_day'),

  CalendarEventsSummary: () => import('modules/calendar/components/events_summary').then(m => m.EventsSummary),
  TaggableEditor: () => import('shared/components/taggable_editor').then(m => ({ TaggableEditor: m.default })),
  TextileTextarea,
  PollingResults,
  FormHelper,
  ConferenceReportHelper,
  Plugins: {
    menuControl,
  },
  StyleGuide: () => import('./entrypoints/style_guide'),
  children: {
    NewChildModal: () => import('./modules/children/components/new_child_modal'),
  },
  ConfirmDepartureHandler,
  utils: {
    schoolUrl: schoolUrlHelper,
    initMultipleSelect: FormHelper.initMultipleSelect,
    initDatePickers,
    initDeleteConfirmModal,
    initMultipleSelectWithRequiredValidation: FormHelper.initMultipleSelectWithRequiredValidation,
  },
  Sparklines: () => import('./modules/sparklines'),
  browserInformation,
  retry,
  openRemoteSheet,
  openRemoteModal,
  openConfirmModal,
  replaceModal,
  replaceWithErrorPage,
  chooseProfilePhoto,
  ajaxErrorHandler,
  objectStore,
};
