import { Controller } from '@hotwired/stimulus'

import Uppy from '@uppy/core';
import Compressor from '@uppy/compressor';
import Dashboard from '@uppy/dashboard';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import AwsS3 from '@uppy/aws-s3';

import I18n from 'i18n-js';

export default class extends Controller {
  static values = {
    allowedFileTypes: { type: String, default: '' },
  }
  static targets = ['flash', 'uploader', 'hiddenField', 'form'];

  connect() {
    this.uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 100,
        minNumberOfFiles: 1,
        minFileSize: 1,
        allowedFileTypes: this.allowedFileTypes,
      },
      locale: this.locale,
    })
      .use(Dashboard, {
        target: this.uploaderTarget,
        inline: true,
        width: '100%',
        height: 'max(60vh, 10rem)',
        proudlyDisplayPoweredByUppy: false,
        hideUploadButton: true,
        maxNumberOfFiles: 100,
        minNumberOfFiles: 1,
        locale: this.locale,
        showNativePhotoCameraButton: true,
        disableInformer: true,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 600,
      })
      .use(AwsS3, {
        companionUrl: '/', // will call the presign endpoint on `/s3/params`
      })
      .use(Compressor, {
        quality: 0.8,
        limit: 5,
        maxWidth: 3264,
        maxHeight: 3264,
      });

    this.uppy.on('complete', this.onUploadSuccess.bind(this));
  }

  onError() {
    this.flashTarget.innerHTML = I18n.t('file_uploader.must_upload_successfully');
  }

  onUploadSuccess(result) {
    if (result.failed.length > 0) {
      this.onError();
      return;
    }

    this.value = result.successful.map(file => ({
      id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    }))

    this.hiddenFieldTarget.value = JSON.stringify(this.value);

    this.formTarget.submit();
  }

  submit(e) {
    e.preventDefault();
    this.uppy.upload();
  }

  disconnect() {
    this.uppy.close();
  }

  // eslint-disable-next-line class-methods-use-this
  get locale() {
    return {
      strings: {
        chooseFiles: I18n.t('file_uploader.choose_file'),
      },
    }
  }

  get allowedFileTypes() {
    if (this.allowedFileTypesValue.length > 0) {
      return this.allowedFileTypesValue.split(',').map(type => type.trim());
    } else {
      return null;
    }
  }
}
